import React from "react";
import { useTranslation } from "react-i18next";

import ExportCsv from "../components/ExportCSV/ExportCSV";
import LoaderWrapper from "../components/LoaderWrapper/LoaderWrapper";
import OperationsTable from "../components/OperationsTable/OperationsTable";
import PagePagination from "../components/PagePagination";
import { MainContainer, TableHeader, Title } from "../components/ReusableStyles";
import { OperationsFor, useGetOperations } from "../data/services/useGetOperations";

const DEFAULT_MAX_OPERATIONS = 20;

type OperationsProps = {
  maxOperationsPerPage?: number;
  recordId?: string;
  hideTransaction?: boolean;
  hideType?: boolean;
  operationsNumber?: number;
  operationsFor?: OperationsFor;
  showJson?: boolean;
  isAccountTab?: boolean;
};

const Operations = (p: OperationsProps) => {
  const {
    operations,
    setOperations,
    page,
    setPage,
    fetchingError,
    setFetchingError,
    server,
    isLoading,
    setIsLoading,
  } = useGetOperations({
    operationsLimit: p.maxOperationsPerPage ?? DEFAULT_MAX_OPERATIONS,
    recordId: p.recordId,
    operationsFor: p.operationsFor,
  });

  const { t } = useTranslation();

  return (
    <MainContainer>
      <TableHeader>
        <Title>{p.operationsNumber ? `${t("operations")} (${p.operationsNumber})` : t("operations")}</Title>
      </TableHeader>
      <LoaderWrapper
        loading={isLoading}
        fetchingError={fetchingError}
        collectionEmpty={operations && operations.records.length < 1}
      >
        <PagePagination
          maxRecordsPerPage={p.maxOperationsPerPage ?? DEFAULT_MAX_OPERATIONS}
          data={operations}
          setData={setOperations}
          page={page}
          setPage={setPage}
          fetchingError={fetchingError}
          setFetchingError={setFetchingError}
          server={server}
          setIsLoading={setIsLoading}
        >
          <OperationsTable
            operations={operations}
            expanded
            hideTransaction={p.hideTransaction}
            hideType={p.hideType}
            showJson={p.showJson}
            isAccountTab={p.isAccountTab}
            addressInView={p.recordId}
          />
        </PagePagination>
      </LoaderWrapper>
      {operations && operations.records.length > 1 && (
        <ExportCsv
          recordsType={
            p.operationsFor === "account"
              ? "operationsForAccount"
              : p.operationsFor === "transaction"
              ? "operationsForTransactions"
              : "operations"
          }
          recordId={p.recordId}
        />
      )}
    </MainContainer>
  );
};

export default Operations;
